import React from 'react';
import cloneDeep from 'lodash.clonedeep';

import Fields from './Fields';
import { FieldsContext } from './context';

/**
 * TODO maybe we could use the displayGeneralErrorMsg prop
 * either as boolean or as string,
 * to be able to customize the generic error message
 * ie. "timeArray child fields are not valid!"
 */
export default function FieldArray({ name, renderAdd, renderFields, tagName = 'div', schema = {}, displayGeneralErrorMsg = true }) {
    const fieldsContext = React.useContext(FieldsContext);
    // if (window.devMode) window.console.log('FieldArray/fieldsContext', fieldsContext); // @debug
    // handle onMount
    React.useEffect(() => {
        if (fieldsContext.values[name] === undefined || fieldsContext.values[name] === null || fieldsContext.values[name].length === 0) {
            fieldsContext.handleChange(name, [{}]);
        }
        fieldsContext.updateState((prevState) => ({
            ...prevState,
            schema: {
                ...prevState.schema,
                [name]: Object.assign({ type: 'fields', fieldsChilds: [] }, schema),
            },
        }));

        return () => {
            //not sure yet if should be updated the parent context
            // delete fieldsContext.values[name];
            // delete fieldsContext.schema[name];
            // fieldsContext.updateState({ value: fieldsContext.values, schema: fieldsContext.schema });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // wait until is populated
    if (fieldsContext.schema[name] === undefined) return null;
    //clone data to avoid mess redux state
    const dataArray = cloneDeep(fieldsContext.values[name]);
    if (dataArray) {
        // attach uId to data if not exist to be able to edit & remove
        dataArray.forEach((e, index) => {
            if (e.uId === undefined) e.uId = index;
        });
    }

    const add = (initialValues = {}) => {
        // if (window.devMode) window.console.log('FieldArray/add/initialValues', initialValues.constructor.name); // @debug
        //get last uId and increment
        let rowData;
        if (initialValues.constructor.name === 'Object') {
            rowData = Object.assign({}, { uId: dataArray[dataArray.length - 1].uId + 1 }, initialValues);
        } else {
            rowData = { uId: dataArray[dataArray.length - 1].uId + 1 };
        }

        // if (window.devMode) window.console.log('FieldArray/add/rowData', rowData); // @debug
        dataArray.push(rowData);
        fieldsContext.handleChange(name, dataArray);
    };
    const handleRemove = (uId) => {
        fieldsContext.handleChange(
            name,
            dataArray.filter((e) => e.uId !== uId)
        );
    };
    const handleSubmit = (data, done) => {
        Object.assign(dataArray[dataArray.findIndex((e) => e.uId === data.uId)], data);
        fieldsContext.handleChange(name, dataArray);
        done();
    };

    return (
        <>
            {dataArray &&
                dataArray.map((obj, index2) => (
                    <Fields tag={tagName} key={obj.id ? obj.id : obj.uId} initialValues={obj} submitOnChange={true} onSubmit={handleSubmit}>
                        {renderFields({ remove: () => handleRemove(obj.uId), isFirst: index2 === 0 ? true : false, values: obj, index: index2 })}
                        <ChildRegisterValidationToParent register={fieldsContext.schema[name].fieldsChilds} />
                    </Fields>
                ))}
            {renderAdd && renderAdd({ add })}
            {fieldsContext.errors[name] && displayGeneralErrorMsg && (
                <div className="invalid-feedback" style={{ display: 'block' }}>
                    {fieldsContext.errors[name]}
                </div>
            )}
        </>
    );
}

function ChildRegisterValidationToParent({ register }) {
    const fieldsContext = React.useContext(FieldsContext);
    React.useEffect(() => {
        register.push(fieldsContext.fieldsChildStateValidate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return null;
}
