// try to imit superagent
import request from 'superagent';
// import { apiFetch } from '_dash/components/util';
const urlApi = '/api_nextjs/'; // proxy to api_nextjs

// v1
/* export const clientFetch = new (function () {
    this.body = '';
    this.method = 'get';
    this.url = ''
    this.get = function (url: string) { this.method = 'get'; this.url = url; return this; }
    this.post = function (url: string) { this.method = 'post'; this.url = url; return this; }
    this.put = function (url: string) { this.method = 'put'; this.url = url; return this; }
    this.delete = function (url: string) { this.method = 'delete'; this.url = url; return this; }
    this.send = function (data: string) { this.body = data; return this; }
    this.exec = async function () {
        try {
            const r = await request(this.method, urlApi + this.url).withCredentials().send(this.body)
            if (r.ok) return r.body;
            else throw new Error("The response is not valid");
        } catch (error) {
            fetch('browserError', {
                method: "POST",
                credentials: 'include', //also save the cookie from CORS
                body: JSON.stringify({ error, args: { url: this.url, method: this.method, body: this.body } }),
            });
            throw error
        }
    }
})() */

// v2
/* type NextFetch = {
    queryVal: string | object,
    data: string | object | undefined,
    method: 'get' | 'post' | 'put' | 'delete',
    url: string,
    get: Function,
    post: Function,
    put: Function,
    delete: Function,
    send: Function,
    query: Function,
    exec: Function,
}
export const clientFetch: NextFetch = {
    data: '',
    method: 'get',
    url: '',
    queryVal: '',
    get: function (url: string) { this.method = 'get'; this.url = url; return this; },
    post: function (url: string) { this.method = 'post'; this.url = url; return this; },
    put: function (url: string) { this.method = 'put'; this.url = url; return this; },
    delete: function (url: string) { this.method = 'delete'; this.url = url; return this; },
    send: function (data: string | object | undefined) { this.data = data; return this; },
    query: function (val: string | object) { this.queryVal = val; return this; },
    exec: async function () {
        try {
            const createReq = request(this.method, urlApi + this.url).withCredentials()
            if (this.data) createReq.send(this.data)
            if (this.queryVal) createReq.query(this.queryVal)
            const r = await createReq
            if (r.ok) return r.body;
            else throw new Error("The response is not valid");
        } catch (error) {
            // console.log(console.trace());
            await request.post(urlApi + 'browserError').withCredentials().send({ message: error.message, href: document.location.href, args: { url: this.url, method: this.method, body: this.data } })
            throw error
        }
    }
} */

const initData: InitData = {
    data: {},
    method: 'get',
    url: '',
    query: {},
};
type InitData = {
    data: object;
    method: 'get' | 'post' | 'put' | 'delete';
    url: string;
    query: object;
};
// v3
export class NextFetch {
    private _data: object;
    private _method: 'get' | 'post' | 'put' | 'delete';
    private _url: string;
    private _query: object;
    constructor(init = {}) {
        const _init = Object.assign({}, initData, init);
        this._data = _init.data;
        this._method = _init.method;
        this._url = _init.url;
        this._query = _init.query;
    }
    public get(url: string) {
        this._method = 'get';
        this._url = url;
        return this;
    }
    public post(url: string) {
        this._method = 'post';
        this._url = url;
        return this;
    }
    public put(url: string) {
        this._method = 'put';
        this._url = url;
        return this;
    }
    public delete(url: string) {
        this._method = 'delete';
        this._url = url;
        return this;
    }
    public send(data: object) {
        this._data = data;
        return this;
    }
    public query(val: object) {
        this._query = val;
        return this;
    }
    public async exec() {
        try {
            const createReq = request(this._method, urlApi + this._url).withCredentials();
            if (this._method !== 'get') createReq.send(this._data);

            // PASS request through coldfusion as middle man
            // const apiFetchData = {
            //     action: 'api.baseapi.httpNextJs',
            //     nextJS_query: this._query,
            //     nextJS_send: this._data,
            //     nextJS_method: this._method,
            //     nextJS_url: urlApi + this._url,
            // };
            // const rf = await apiFetch(apiFetchData);
            // r = { body: rf === '' ? rf : JSON.parse(rf), ok: true };

            // PASS request direct to NEXTJS proxy
            if (Object.keys(this._query).length) createReq.query(this._query);
            const r = await createReq;

            if (r.ok) {
                if (r.body) {
                    switch (r.body.status) {
                        case 'session_expired':
                            window.swal({ type: 'info', text: r.body.msg, showCancelButton: false, confirmButtonText: 'go to login', onConfirm: () => window.location.reload() });
                            // if return empty promise.then() will stop process
                            return new Promise(function (resolve, reject) {});
                        case 'company_expired':
                            window.swal({
                                type: 'warning',
                                title: r.body.msg,
                                showConfirmButton: false,
                            });
                            // if return empty promise.then() will stop process
                            return new Promise(function (resolve, reject) {});
                    }
                }
                return r.body;
            } else throw new Error('The response is not valid');
        } catch (error: any) {
            window.swal({
                type: 'warning',
                text: !window.devMode ? 'Unfortunately, an error has occurred' : error.message,
                confirmButtonText: 'Reload Page',
                showCancelButton: window.devMode,
                onConfirm: () => window.location.reload(),
            });
            // console.log(console.trace());
            // await request
            //     .post(urlApi + 'browserError')
            //     .withCredentials()
            //     .send({ message: error.message, href: document.location.href, NextFetch: this });
            throw error;
        }
    }
}

/* await fetch(urlApi + 'browserError', {
    method: "POST",
    credentials: 'include', //also save the cookie from CORS
    headers: { 'Content-Type': 'application/json' },
    // referrerPolicy: 'origin-when-cross-origin', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify({ asd: 'asd233' }),
}); */

export class FetchNextApi {
    private url: string;
    private options: RequestInit;

    constructor(url: string, options: RequestInit = {}) {
        this.url = url;

        this.options = options;
        
        if (typeof options.headers === 'undefined') {
            this.options.headers = new Headers();
        } else {
            this.options.headers = new Headers(options.headers);
        }

        if (typeof options.credentials === 'undefined') {
            this.options.credentials = 'include';
        }
    }

    public setMethod(method: string) {
        this.options.method = method;
        return this;
    } 

    public setJson(input: object) {
        (this.options.headers as Headers).append('Content-Type', 'application/json');
        this.options.body = JSON.stringify(input);

        return this;
    }

    public async fetch() {
        const fullUrl = urlApi + this.url;

        return fetch(fullUrl, this.options).then(fetchResponseMiddleware);
    }

    public async downloadFile(downloadName: string = '') {
        return this.fetch()
            .then(async response => {
                const blob = await response.blob();

                if (!downloadName.length) {
                    downloadName = this.getDispositionFileName(response);
                }

                const a = document.createElement('a');
        
                a.href = window.URL.createObjectURL(blob);
                a.download = downloadName;
                a.style.display = 'none';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            });
    }

    private getDispositionFileName(response: Response): string {
        const contentDisposition = response.headers.get('Content-Disposition');

        let downloadName = 'download';

        if (contentDisposition) {
            let filename = '';

            const index = contentDisposition.lastIndexOf('filename=');

            if (index !== -1) {
                filename = contentDisposition.substring(index + 'filename='.length).trim();

                if (filename.length) {
                    downloadName = filename;
                }
            }
        }

        return downloadName;
    }
}

const fetchResponseMiddleware = async (response: Response) => {
    return Promise.resolve(response)
        .then((response) => {
            if (response.ok) {
                return response;
            } else {
                throw new Error('Something went wrong ...');
            }
        })
        .then((response) => {
            const contentType = response.headers.get('content-type');

            // check generic error responses from nextjs server - which are in json format
            if (contentType && contentType.includes('application/json')) {
                return response.json().then((json) => {
                    if (json.success === true) {
                        return json;
                    } else {
                        switch (json.status) {
                            case 'session_expired':
                                window.swal({ type: 'info', text: json.msg, showCancelButton: false, confirmButtonText: 'go to login', onConfirm: () => window.location.reload() });
                                // if return empty promise then() will stop process
                                return new Promise(function (resolve, reject) {});

                            case 'company_expired':
                                window.swal({
                                    type: 'warning',
                                    title: json.msg,
                                    showConfirmButton: false,
                                });
                                break;

                            case 'error':
                                return json

                            default:
                                const swalData = {
                                    type: 'warning',
                                    text: json.msg,
                                    title: json.title !== undefined && json.title.length > 0 ? json.title : undefined,
                                };
                                window.swal(swalData);

                                return json;
                        }
                    }
                });
            } else {
                return response;
            }
        })
        .catch((error) => {
            // window.console.log('util.apiFetch.catch.error', error); // @debug
            window.swal({
                type: 'warning',
                text: !window.devMode ? 'Unfortunately, an error has occurred' : error.message,
            });

            return {
                error: error,
            };
        });
};
