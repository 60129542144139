import styled from 'styled-components';
import variables from 'scss/base/_variables.module.scss';

export const MultipleUploadSt = styled.div`
    position: relative;
    margin: 20px 0 10px;
    h2 {
        font-size: 14px;
        margin-bottom: 10px;
        color: #555;
    }
    .attach-icon-box {
        position: relative;
        cursor: pointer;
        color: ${variables.colorBase};
        cursor: default;
        &.isDisabled {
            &:hover {
                color: ${variables.colorBase};
            }
        }
        &:hover {
            color: ${variables.colorPrimary};
            .icon .no_item {
                color: ${variables.colorPrimary};
            }
        }
        .icon {
            position: relative;
            display: inline-block;
            margin-right: 13px;
            font-size: 18px;
            color: ${variables.colorPrimary};
            .no_item {
                font-size: 12px;
                position: absolute;
                right: -5px;
                bottom: 0;
                color: ${variables.colorBase};
            }
        }
    }
    .dropdown-menu {
        max-width: 230px;
        position: absolute;
        top: 0;
        &.tooltip-dropdown-menu.pos-top .arrow::before,
        &.tooltip-dropdown-menu.pos-top .arrow::after {
            display: none;
        }
    }
    .upload-box {
        .inner {
            position: relative;
            padding: 10px 8px 15px;
            margin-bottom: 0;
            &.noFile {
                padding: 20px;
                border: 1px dashed ${variables.colorBase};
                margin: 10px;
                text-align: center;
                display: flex;
                flex-flow: row wrap;
                @media (max-width: 992px) {
                    padding: 15px 0;
                }
            }
            h2 {
                margin-top: 0;
            }
            .cta-fileAdded {
                display: flex;
                flex-flow: row wrap;
                width: 100%;
                p {
                    margin-bottom: 0;
                    margin-top: 10px;
                    font-weight: 600;
                    font-size: 14px;
                }
                .cta-option {
                    display: flex;
                    width: 100%;
                    justify-content: space-around;
                }
                .label-inputFile,
                .item-add {
                    font-size: 13px;
                    padding: 10px 0px;
                    margin: 5px 0;
                    color: ${variables.colorPrimary};
                    border: none;
                    &:hover {
                        color: ${variables.colorSecondary};
                    }
                    i {
                        font-size: 13px;
                        margin-right: 5px;
                    }
                }
            }
        }
        .attach_row {
            padding: 10px 8px 15px;
            li {
                padding-right: 30px;
            }
            .item-name {
                display: block;
                max-width: 100%;
            }
        }
        ul.listToAdd {
            margin-bottom: 15px;
            li {
                position: relative;
                padding-bottom: 7px;
                margin-bottom: 8px;
                border-bottom: 1px solid rgba(${variables.colorPrimary}, 0.4);
                position: relative;
                padding-right: 20px;
                font-size: 13px;
                overflow: hidden;
                display: flex;
            }
            .item-name {
                white-space: nowrap;
                max-width: 180px;
                overflow: hidden;
                display: inline-block;
            }
            .item-size {
                margin-left: auto;
                font-weight: 600;
                padding-left: 3px;
                font-size: 12px;
                white-space: nowrap;
            }
            .delete {
                position: absolute;
                right: -4px;
                top: -7px;
                color: ${variables.colorRed};
                cursor: pointer;
                font-size: 14px;
                transition: all ease 0.3s;
                z-index: 11;
                font-size: 18px;
                padding: 5px;
                i {
                    color: ${variables.colorRed};
                }
                &:hover {
                    i {
                        font-size: 16px;
                    }
                }
            }
        }
        .label-inputFile {
            color: ${variables.colorPrimary};
            font-size: 14px;
            display: block;
            text-align: center;
            border: 1px solid ${variables.colorPrimary};
            padding: 10px;
            border-radius: 25px;
            margin: 10px auto 0;
            cursor: pointer;
            max-width: 140px;
            i {
                margin-right: 10px;
                font-size: 12px;
            }
        }
    }
`;
