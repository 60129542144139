import React, { useState, useEffect } from 'react';
//import ClassNames from 'classnames';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
    IconVideoFile,
    IconMedia,
    IconDocuments,
    IconProjects,
    IconPortofolios,
    IconReport,
    IconBriefCase,
    IconShortlist,
    IconVideoInterview,
    IconFaceToFace,
    IconCall,
    IconVetting,
    IconOffer,
    IconHire,
    IconUsers, // for getIconStep
} from '_dash/components/Icon';

import { PageLoader, BackgroundWrapSt } from '_dash/styleComponents/CommonStyle';
import Modal from 'modules/react-modal'; // for ModalNotMediaSupport
/*
export function getLang(langId, langProficiencyId) {
    const lang = { langName: 'not found', langProficiencyName: 'not found' };
    langList.forEach((l) => {
        if (l.id === parseInt(langId)) lang.langName = l.name;
    });
    langProficiencyList.forEach((l) => {
        if (l.id === parseInt(langProficiencyId)) lang.langProficiencyName = l.name;
    });
    return lang;
}

export function getLangs(langIds, langProficiencyIds) {
    const langs = [];
    if (langIds.toString().length > 0) {
        const langIdsArray = langIds.toString().split(',');
        const langProficiencyIdsArray = langProficiencyIds.toString().split(',');
        for (let index = 0; index < langIdsArray.length; index++) {
            langs.push(getLang(langIdsArray[index], langProficiencyIdsArray[index]));
        }
    }
    return langs;
}*/

export const itemDisplayDates = (argStartDate, argEndDate = '') => {
    // startDate
    const startDate = new Date(argStartDate);
    const startDateDisplay = startDate.toLocaleString('en-IE', { timeZone: 'UTC', month: 'short', year: 'numeric' });

    // endDate
    let endDateDisplay = 'Present';
    let endDate = new Date();
    if (argEndDate.length > 0) {
        endDate = new Date(argEndDate);
        endDateDisplay = endDate.toLocaleString('en-IE', {
            timeZone: 'UTC',
            month: 'short',
            year: 'numeric',
        });
    }
    const dateDiffDisplay = dateDiff(startDate, endDate);

    return { startDateDisplay, endDateDisplay, dateDiffDisplay };
};

// new structure: hierarchy
export const EmployerName = ({
    id,
    showRole = true,
    showTitle = false,
    showImage = true,
    onlyImage = false,
    prependName = '',
    isThumb = true,
    showRoleTooltip = false,
    customLabel = '',
}) => {
    const hierarchyUsers = useSelector((state) => state.hierarchyUsers);
    const user = getEmployer({ id, hierarchyUsers });
    const roleName = useSelector((state) => {
        if (user === undefined) return '';
        const roleIndex = state.roles.findIndex((e) => e.id === user.roleId);
        return roleIndex !== -1 ? state.roles[roleIndex].name : 'not found';
    });
    if (user === undefined) {
        return onlyImage ? (
            <span className="box box-del">
                <span className="noImg" title="deleted" style={{ backgroundColor: 'red' }}>
                    Del
                </span>
            </span>
        ) : (
            <span className="box box-del">
                {showImage && (
                    <span className="noImg" title="deleted" style={{ backgroundColor: 'red' }}>
                        Del
                    </span>
                )}
                <span className="text-danger">user deleted</span>
            </span>
        );
    }

    let userLabel = '';
    if (customLabel.length > 0) {
        userLabel = customLabel;
    } else {
        const title = user.title === '' ? '' : '- ( ' + user.title + ')';
        userLabel = `${prependName}${user.firstName} ${user.lastName} ${showTitle ? title : ''}`; //${showRole ? ' - ' + roleName : ''}`;
    }

    const propsTootip = {};
    if (!onlyImage && showRoleTooltip) {
        propsTootip.className = 'has-tooltip-top';
        propsTootip['data-tooltip'] = 'Role: ' + roleName;
    }

    const userInitials = getInitials(user.firstName + ' ' + user.lastName);
    const image =
        user.photoThumb.indexOf('defaultUser.jpg') > -1 ? (
            <span
                className="noImg"
                title={userLabel}
                style={{ backgroundColor: user.photoThumb.indexOf('defaultUser.jpg') > -1 ? getColorName(user.firstName + ' ' + user.lastName) : '' }}
            >
                {userInitials}
            </span>
        ) : isThumb ? (
            <img key={`employerName-image-${id}`} alt={userLabel} className="noImg img-circle" src={user.photoThumb} title={userLabel} width="48" />
        ) : (
            <img key={`employerName-image-${id}`} alt={userLabel} className="noImg img-circle" src={user.photo} title={userLabel} />
        );

    return onlyImage ? (
        image
    ) : (
        <span {...propsTootip} className="box">
            {showImage && image}&nbsp;{userLabel} {showRole ? <b className="role-name">{' - ' + roleName}</b> : ''}
        </span>
    );
};
EmployerName.propTypes = {
    id: PropTypes.number,
    showRole: PropTypes.bool,
    showImage: PropTypes.bool,
    onlyImage: PropTypes.bool,
    prependName: PropTypes.string,
    showRoleTooltip: PropTypes.bool,
};

// old structure ie parent - child
const EmployerNameOld = ({
    id,
    showRole = true,
    showTitle = false,
    showImage = true,
    onlyImage = false,
    prependName = '',
    isThumb = true,
    showRoleTooltip = false,
    customLabel = '',
}) => {
    const {
        users,
        company: { parentUsers },
    } = useSelector((state) => state);
    const user = getEmployer({ id, users, parentUsers });
    const roleName = useSelector((state) => {
        if (user === undefined) return '';
        const roleIndex = state.roles.findIndex((e) => e.id === user.roleId);
        return roleIndex !== -1 ? state.roles[roleIndex].name : 'not found';
    });
    if (user === undefined) {
        return onlyImage ? (
            <span className="box box-del">
                <span className="noImg" title="deleted" style={{ backgroundColor: 'red' }}>
                    Del
                </span>
            </span>
        ) : (
            <span className="box box-del">
                {showImage && (
                    <span className="noImg" title="deleted" style={{ backgroundColor: 'red' }}>
                        Del
                    </span>
                )}
                <span className="text-danger">user deleted</span>
            </span>
        );
    }

    let userLabel = '';
    if (customLabel.length > 0) {
        userLabel = customLabel;
    } else {
        const title = user.title === '' ? '' : '- ( ' + user.title + ')';
        userLabel = `${prependName}${user.firstName} ${user.lastName} ${showTitle ? title : ''}`; //${showRole ? ' - ' + roleName : ''}`;
    }

    const propsTootip = {};
    if (!onlyImage && showRoleTooltip) {
        propsTootip.className = 'has-tooltip-top';
        propsTootip['data-tooltip'] = 'Role: ' + roleName;
    }

    const userInitials = getInitials(user.firstName + ' ' + user.lastName);
    const image =
        user.photoThumb.indexOf('defaultUser.jpg') > -1 ? (
            <span
                className="noImg"
                title={userLabel}
                style={{ backgroundColor: user.photoThumb.indexOf('defaultUser.jpg') > -1 ? getColorName(user.firstName + ' ' + user.lastName) : '' }}
            >
                {userInitials}
            </span>
        ) : isThumb ? (
            <img key={`employerName-image-${id}`} alt={userLabel} className="img-circle" src={user.photoThumb} title={userLabel} width="48" />
        ) : (
            <img key={`employerName-image-${id}`} alt={userLabel} className="img-circle" src={user.photo} title={userLabel} />
        );

    return onlyImage ? (
        image
    ) : (
        <span {...propsTootip} className="box">
            {showImage && image}&nbsp;{userLabel} {showRole ? <b className="role-name">{' - ' + roleName}</b> : ''}
        </span>
    );
};
EmployerNameOld.propTypes = {
    id: PropTypes.number,
    showRole: PropTypes.bool,
    showImage: PropTypes.bool,
    onlyImage: PropTypes.bool,
    prependName: PropTypes.string,
    showRoleTooltip: PropTypes.bool,
};

export const EmployerNameFromData = ({
    user,
    showRole = true,
    showTitle = false,
    showImage = true,
    onlyImage = false,
    prependName = '',
    isThumb = true,
    showRoleTooltip = false,
    customLabel = '',
}) => {
    if (user.photoThumb === undefined) {
        user.photoThumb = user.photo;
    }

    const roleName = useSelector((state) => {
        const roleIndex = state.roles.findIndex((e) => e.id === user.roleId);
        return roleIndex !== -1 ? state.roles[roleIndex].name : 'not found';
    });

    let userLabel = '';
    if (customLabel.length > 0) {
        userLabel = customLabel;
    } else {
        const title = user.title === '' ? '' : ' - ( ' + user.title + ')';
        userLabel = `${prependName}${user.firstName} ${user.lastName}${showTitle ? title : ''}`; //${showRole ? ' - ' + roleName : ''}`;
    }

    const propsTootip = {};
    if (!onlyImage && showRoleTooltip) {
        propsTootip.className = 'has-tooltip-top';
        propsTootip['data-tooltip'] = 'Role: ' + roleName;
    }

    const userInitials = getInitials(user.firstName + ' ' + user.lastName);
    const useInitials = !user.photoThumb || user.photoThumb.length === 0 || user.photoThumb.indexOf('defaultUser.jpg') > -1;
    const image = useInitials ? (
        <span className="noImg" title={userLabel} style={{ backgroundColor: useInitials ? getColorName(user.firstName + ' ' + user.lastName) : '' }}>
            {userInitials}
        </span>
    ) : isThumb ? (
        <img key={`employerName-image-${user.id}`} alt={userLabel} className="img-circle" src={user.photoThumb} title={userLabel} width="48" />
    ) : (
        <img key={`employerName-image-${user.id}`} alt={userLabel} className="img-circle" src={user.photo} title={userLabel} />
    );

    return onlyImage ? (
        image
    ) : (
        <span {...propsTootip} className="box">
            {showImage && image}&nbsp;{userLabel} {showRole ? <b className="role-name">{' - ' + roleName}</b> : ''}
        </span>
    );
};
EmployerNameFromData.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        nrJobs: PropTypes.number,
        photo: PropTypes.string,
        role: PropTypes.string,
        roleId: PropTypes.number,
        title: PropTypes.string,
    }),
    showRole: PropTypes.bool,
    showImage: PropTypes.bool,
    onlyImage: PropTypes.bool,
    prependName: PropTypes.string,
    showRoleTooltip: PropTypes.bool,
};

export function getEmployer({ id, hierarchyUsers }) {
    /* const userIndex = users.findIndex((e) => e.id === id);
    if (userIndex === -1 && parentUsers) {
        const userParentIndex = parentUsers.findIndex((e) => e.id === id);
        return parentUsers[userParentIndex];
    } else return users[userIndex]; */

    return hierarchyUsers.find((item) => item.id === id);
}

// old structure parent - child
function getEmployerOld({ id, users, parentUsers }) {
    const userIndex = users.findIndex((e) => e.id === id);
    if (userIndex === -1 && parentUsers) {
        const userParentIndex = parentUsers.findIndex((e) => e.id === id);
        return parentUsers[userParentIndex];
    } else return users[userIndex];
}

export function CandidateName({ customLabel = '', showImage = true, onlyImage = false }) {
    const image = (
        <span className="noImg" title={customLabel} style={{ backgroundColor: getColorName(customLabel) }}>
            {getInitials(customLabel)}
        </span>
    );

    return onlyImage ? (
        image
    ) : (
        <span className="box">
            {showImage && image}&nbsp;{customLabel}
        </span>
    );
}
CandidateName.propTypes = {
    customLabel: PropTypes.string,
    showImage: PropTypes.bool,
    onlyImage: PropTypes.bool,
};

export function apiFetch(data, method = 'POST', appendQuery = false) {
    // console.log('data', JSON.stringify(data));
    // convert data in FormData if is the case
    if (!(data instanceof FormData)) {
        const form_data = new FormData();
        for (const key in data) {
            // console.log('data', JSON.stringify(data));
            // console.log({ key });
            if (data[key] === undefined) continue; // skip undefined keys to avoid break on next line
            if (data[key] !== null && (data[key].constructor.name === 'Array' || data[key].constructor.name === 'Object')) {
                // handle multipleUpload data
                if (data[key].constructor.name === 'Array' && data[key].length > 0 && data[key][0].constructor.name === 'File') {
                    data[key].forEach((file, i) => {
                        form_data.append(key + ',' + i, file);
                    });
                } else data[key] = JSON.stringify(data[key]);
            }
            //avoid add again in form multipleUpload data
            if (!(data[key].constructor.name === 'Array' && data[key].length > 0 && data[key][0].constructor.name === 'File')) {
                form_data.append(key, data[key]);
            }
        }
        data = form_data;
    }
    return fetch(process.env.REACT_APP_API_URL + (appendQuery ? window.location.search : ''), {
        method: method,
        credentials: 'include', //also save the cookie from CORS
        body: data,
    })
        .then((response) => {
            // window.console.log('util.apiFetch.then1.response', response); // @debug
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
        .then((json) => {
            // window.console.log('util.apiFetch.then2.json', json); // @debug
            if (json.success === true) {
                return json.data;
            } else {
                switch (json.status) {
                    case 'session_expired':
                        // avoid first call to stop infinite call
                        if ('api.baseapi.onMount' !== data.get('action')) {
                            // window.location.reload();
                            window.swal({ type: 'info', text: json.msg, showCancelButton: false, confirmButtonText: 'go to login', onConfirm: () => window.location.reload() });
                            // if return empty promise then() will stop process
                            return new Promise(function (resolve, reject) {
                                // setTimeout(() => reject(json.data), 5000);
                            });
                        } else {
                            return json.data;
                        }
                    case 'company_expired':
                        window.swal({
                            type: 'warning',
                            title: json.msg,
                            showConfirmButton: false,
                        });
                        break;
                    //don't need anymore to set path name
                    // stop infinite loop on same path
                    // if (window.location.pathname != '/dash/' && !RegExp('/dash/company/(.*)/login/').test(window.location.pathname)) {
                    // 	window.location.pathname = '/dash/';
                    // }
                    // break;
                    default:
                        // throw new Error(json.msg); // send this to catch? or simply deal with it here? :hmm:
                        const swalData = {
                            type: 'warning',
                            text: json.msg ? json.msg : 'An error has ocurred!',
                            title: json.title !== undefined && json.title.length > 0 ? json.title : undefined,
                        };
                        window.swal(swalData);
                        break;
                }
            }
        })
        .catch((error) => {
            // window.console.log('util.apiFetch.catch.error', error); // @debug
            window.swal({
                type: 'warning',
                text: !window.devMode ? 'Unfortunately, an error has occurred' : error.message,
            });
            return {
                error: error,
            };
        });
}

export function previewPdfFile(fileUrl) {
    if (typeof navigator.mimeTypes['application/pdf'] !== 'undefined') {
        // use built in pdf render
        let ifr = document.createElement('iframe');
        ifr.src = fileUrl;
        ifr.style.display = 'none';
        document.body.appendChild(ifr);
        ifr.onload = function () {
            setTimeout(function () {
                ifr.focus();
                ifr.contentWindow.print();
            }, 300);
        };
    } else {
        //user pdfjs viewer.html
        window.open(process.env.REACT_APP_SITE_URL + 'pdfjs/web/viewerPrint.html?file=' + fileUrl, '_blank', 'toolbar=no, scrollbars=no, resizable=yes, top=1');
    }
}

export const eventDelay = (function () {
    let timer = 0;
    return function (callback, sleepTime = 500) {
        clearTimeout(timer);
        timer = setTimeout(callback, sleepTime);
    };
})();

export function getTimeElapsed(date = Date()) {
    const time = Date.parse(Date()) - Date.parse(date);

    const minutes = parseInt(time / 1000 / 60);
    if (minutes < 60) {
        return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    }
    const hours = parseInt(minutes / 60);
    if (hours < 24) {
        return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    }
    const days = parseInt(hours / 24);
    if (days < 30) {
        return `${days} hour${days > 1 ? 's' : ''} ago`;
    }
    const months = parseInt(days / 30);
    if (months < 12) {
        return `${months} hour${months > 1 ? 's' : ''} ago`;
    }
    const years = parseInt(months / 12);
    return `${years} hour${years > 1 ? 's' : ''} ago`;
}

export function getDateTimeDiff(eventDate, nowDate = Date()) {
    let ms = Date.parse(eventDate) - Date.parse(nowDate),
        prefix = '',
        suffix = '';
    if (ms > 0) {
        // in future
        prefix = 'in';
    } else {
        // in the past
        suffix = 'ago';
        ms *= -1;
    }
    // if (window.devMode) window.console.log('getDateTimeDiff', {eventDate, nowDate, ms, prefix, suffix}); // @debug

    const minutes = parseInt(ms / 1000 / 60);
    if (minutes < 1) {
        return 'now';
    }
    if (minutes < 60) {
        return `${prefix} ${minutes} minute${minutes > 1 ? 's' : ''} ${suffix}`.trim();
    }

    const hours = parseInt(minutes / 60);
    if (hours < 24) {
        return `${prefix} ${hours} hour${hours > 1 ? 's' : ''} ${suffix}`.trim();
    }

    const days = parseInt(hours / 24);
    if (days < 30) {
        return `${prefix} ${days} day${days > 1 ? 's' : ''} ${suffix}`.trim();
    }
    const months = parseInt(days / 30);
    if (months < 12) {
        return `${prefix} ${months} month${months > 1 ? 's' : ''} ${suffix}`.trim();
    }
    const years = parseInt(months / 12);
    return `${prefix} ${years} year${years > 1 ? 's' : ''} ${suffix}`.trim();
}

export function getConversion(current, prev) {
    if (current === prev)
        return (
            <span className="percent up">
                <i className="fal fa-angle-up" />
                0%
            </span>
        );
    else if (current > prev && prev === 0)
        return (
            <span className="percent up">
                <i className="fal fa-angle-up" />
                100%
            </span>
        );
    else if (current > prev && prev > 0)
        return (
            <span className="percent up">
                <i className="fal fa-angle-up" />
                {((current / prev) * 100).toFixed(2)}%
            </span>
        );
    else if (current < prev && current === 0)
        return (
            <span className="percent down">
                <i className="fal fa-angle-down" />
                100%
            </span>
        );
    else if (current < prev && current > 0)
        return (
            <span className="percent down">
                <i className="fal fa-angle-down" />
                {((prev / current) * 100).toFixed(2)}%
            </span>
        );
}

export function SourceIcon({ name }) {
    const nameTrim = name.trim();
    if (['facebook-f', 'facebook', 'google-plus-g', 'google', 'linkedin-in', 'linkedin', 'twitter'].includes(nameTrim)) {
        return (
            <span className="has-tooltip-arrow has-tooltip-bottom" data-tooltip={nameTrim}>
                <i className={'fab fa-' + nameTrim} data-tooltip={nameTrim} />
            </span>
        );
    } else if (['careerjet', 'indeed', 'apply.indeed', 'jobrapido', 'rezoomo', 'trovit', 'google'].includes(nameTrim)) {
        return (
            <span className="has-tooltip-arrow has-tooltip-bottom" data-tooltip={nameTrim}>
                <img alt={nameTrim} src={`${process.env.REACT_APP_SITE_URL}src/images/icons/source/` + nameTrim + `.png`} />
            </span>
        );
    } else if (['android-app:'].includes(nameTrim)) {
        return (
            <span className="has-tooltip-arrow has-tooltip-bottom" data-tooltip={nameTrim}>
                <img alt={nameTrim} src={`${process.env.REACT_APP_SITE_URL}src/images/icons/source/android.png`} />
            </span>
        );
    } else if (name === '') {
        return 'unknown';
    } else {
        return (
            <span className="has-tooltip-arrow has-tooltip-bottom" data-tooltip={nameTrim}>
                <img alt={nameTrim} src={'https://www.google.com/s2/favicons?domain=' + nameTrim} />
            </span>
        );
    }
}
export function SourceSvg({ name, x, y, clipP }) {
    const nameTrim = name.trim();
    if (['facebook-f', 'facebook', 'google-plus-g', 'google', 'linkedin-in', 'linkedin', 'twitter'].includes(nameTrim)) {
        return <image x={x} y={y} href={`${process.env.REACT_APP_SITE_URL}src/images/icons/svg/' + nameTrim + '.png`} height="16" width="16" />;
    } else if (['careerjet', 'indeed', 'jobrapido', 'rezoomo', 'trovit', 'google'].includes(nameTrim)) {
        return <image x={x} y={y} href={`${process.env.REACT_APP_SITE_URL}src/images/icons/source/' + nameTrim + '.png`} height="16" width="16" />;
    } else if (['android-app:'].includes(nameTrim)) {
        return <image x={x} y={y} href={`${process.env.REACT_APP_SITE_URL}src/images/icons/source/android.png`} height="16" width="16" />;
    } else if (name === '') {
        return <image x={x} y={y} href={`${process.env.REACT_APP_SITE_URL}src/images/icons/svg/unknown.png`} height="16" width="16" />;
    } else {
        return <image x={x} y={y} href={'https://www.google.com/s2/favicons?domain=' + nameTrim} height="16" width="16" />;
    }
}

export const preLoading = () => {
    return (
        <PageLoader>
            <div className="loader">
                <div className="blobs">
                    <div className="blob-center"></div>
                    <div className="blob"></div>
                    <div className="blob"></div>
                    <div className="blob"></div>
                    <div className="blob"></div>
                    <div className="blob"></div>
                    <div className="blob"></div>
                </div>
                {/* <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                    <defs>
                        <filter id="goo">
                            <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
                            <feColorMatrix in="blur" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="goo" />
                            <feBlend in="SourceGraphic" in2="goo" />
                        </filter>
                    </defs>
                </svg> */}
            </div>
        </PageLoader>
    );
};
// OLD old PRELOADING
/*export const preLoading = () => {
	return (
		<div className="smooth-loader">
			<div className="loader-md">
				<div className="pulse-circles">
					<div className="ball ball-1"></div>
					<div className="ball ball-2"></div>
					<div className="ball ball-3"></div>
					<div className="ball ball-4"></div>
				</div>
			</div>
		</div>
		);
};*/
// OLD PRELOADING
/*export const preLoading = () => {
    return (
        <div className="loader-wrapper">
             <div className="inner">
				<img src={`${process.env.REACT_APP_SITE_URL}src/images/layout/miniLogo.png`} alt="" />
				<div className="loader" />
			</div> 

            <div className="lds-spin">
                <div>
                    <div />
                </div>
                <div>
                    <div />
                </div>
                <div>
                    <div />
                </div>
                <div>
                    <div />
                </div>
                <div>
                    <div />
                </div>
                <div>
                    <div />
                </div>
                <div>
                    <div />
                </div>
                <div>
                    <div />
                </div>
            </div>
        </div>
    );
};*/

// commented because render twice
/**
 * @param importFunc = `() => import('./path')`
 * @param props = to be pass in component
 * @param autoClose = set false when want to close later in child's the loader. ex: you make some fetch in child's and want the loader to persist until the fetch is done
 */
/* export function load(importFunc, props = {}, autoClose = true, fallback = null) {
	const [Component, setComponent] = useState(fallback);

	importFunc().then(comp => setComponent(comp));
	if (Component !== null) {
		if (autoClose) {
			window.loader(false);
		}
		return <Component.default {...props} />;
	}
	return null;
} */

export function getEmbedVideoUrl(url) {
    const videoStyle = {
        margin: '0 auto',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        right: '0',
        bottom: '0',
        left: '0',
        width: '70%',
        height: '70%',
        border: 'none',
    };

    const youtubeId = youtubeUrlParser(url);
    if (youtubeId) return <iframe title="youtube" width="560" height="315" src={'https://www.youtube.com/embed/' + youtubeId} style={videoStyle} />;
    const vimeoId = vimeoUrlParser(url);
    if (vimeoId) return <iframe title="vimeo" width="560" height="315" src={'https://player.vimeo.com/video/' + vimeoId} style={videoStyle} />;

    return 'not a valid embed video url';
}

export function youtubeUrlParser(url) {
    var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\\&v=)([^#\\&\\?]*).*/;
    var match = url.match(regExp);
    if (match && match[2].length === 11) {
        return match[2];
    } else {
        return false;
    }
}

export function vimeoUrlParser(url) {
    var match = /vimeo.*\/(\d+)/i.exec(url);
    if (match) {
        return match[1];
    } else {
        return false;
    }
}

export function urlCheckHttp(url) {
    const regExp = /^(http(s|):\/\/).*/;
    const match = url.match(regExp);
    if (match) {
        return url;
    } else {
        return 'http://' + url;
    }
}

export function stringToBase64(text) {
    return btoa(unescape(encodeURIComponent(text)));
}

/**
 * used to render media[image,audio,video] when the src  can be [a_path_to_file,base64_string]
 */
export function FileSrc({ file, render }) {
    const [fileSrc, setFileSrc] = useState('');
    useEffect(() => {
        if (file !== undefined) {
            switch (file.constructor.name) {
                case 'Blob':
                case 'File': {
                    const reader = new FileReader();
                    reader.addEventListener('load', () => setFileSrc(reader.result), false);
                    reader.readAsDataURL(file);
                    break;
                }
                case 'String':
                    setFileSrc(file);
                    break;
                default:
                    break;
            }
        }
    }, [file]);

    if (file !== undefined && file !== '') {
        return render(fileSrc);
    } else {
        return null;
    }
}

/* const yesterday = function(date1) {
	var dt = new Date(date1);
	return new Date(dt.setDate(dt.getDate() - 1)).toString();
}; */
export const dateFormat = (date, showTime = false, onlyTime = false) => {
    if (date === '' || date === undefined || date === null) {
        return '';
    }
    const dateObj = new Date(date);
    let options = { month: 'short', day: 'numeric', year: 'numeric' };
    if (showTime) {
        options = { month: 'short', day: 'numeric', year: 'numeric', hour: '2-digit', minute: '2-digit' };
        return dateObj.toLocaleDateString('en-IE', options);
    }

    let optionsTime;
    if (onlyTime) {
        optionsTime = { hour: '2-digit', minute: '2-digit' };
        return dateObj.toLocaleTimeString('en-IE', optionsTime);
    }

    const today = new Date();
    const oneDayTimeStamp = 1000 * 60 * 60 * 24; // Milliseconds in a day
    const diff = today - oneDayTimeStamp;
    const yesterdayDate = new Date(diff);
    //window.console.log('d===', yesterdayDate.getDate());

    if (dateObj.getDate() === today.getDate() && dateObj.getMonth() === today.getMonth() && dateObj.getFullYear() === today.getFullYear()) {
        return 'Today';
    } else if (dateObj.getDate() === yesterdayDate.getDate() && dateObj.getMonth() === yesterdayDate.getMonth() && dateObj.getFullYear() === yesterdayDate.getFullYear()) {
        return 'Yesterday';
    } else {
        return dateObj.toLocaleDateString('en-IE', options);
    }
};

export const innerText = (jsx) => {
    // String literals.
    if (typeof jsx === 'string') {
        return jsx;
    }
    // Numeric children.
    if (typeof jsx === 'number') {
        return jsx.toString();
    }
    // Array of children.
    if (Array.isArray(jsx)) {
        return jsx.reduce((previous, current) => previous + innerText(current), '');
    }
    // "Children!" ~ Chef
    if (jsx !== null && Object.prototype.hasOwnProperty.call(jsx, 'props') && Object.prototype.hasOwnProperty.call(jsx.props, 'children')) {
        return innerText(jsx.props.children);
    }
    // Default
    return '';
};

export function slugify(text) {
    return text
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(/[^\w-]+/g, '') // Remove all non-word chars
        .replace(/--+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, ''); // Trim - from end of text
}

// @date type: Date
export function DateFormatDB(date, zeroFill = true, time = false, seconds = true) {
    let month = '' + (date.getMonth() + 1),
        day = '' + date.getDate(),
        year = '' + date.getFullYear(),
        hour = '' + date.getHours(),
        minute = '' + date.getMinutes(),
        second = '' + date.getSeconds();

    if (zeroFill && month.length < 2) month = '0' + month;
    if (zeroFill && day.length < 2) day = '0' + day;
    if (zeroFill && hour.length < 2) hour = '0' + hour;
    if (zeroFill && minute.length < 2) minute = '0' + minute;

    let result = [year, month, day].join('-');
    if (time) {
        let timeArr = [hour, minute];
        if (seconds) {
            timeArr.push(second);
        }
        result += ' ' + timeArr.join(':');
    }
    return result;
}

/**
 * Mirror CF lib.cfc/generateSlug function
 * @param {String} text Required. The string to slugify
 * @param {String} spacer Default '-'
 * @param {String} allowedChars Default ''
 */
export function cfSlug(text, spacer = '-', allowedChars = '') {
    const str = text
        .trim()
        .toLowerCase()
        .replace(new RegExp('[àáâãäåăâăâ]', 'ig'), 'a')
        .replace(new RegExp('[èéêë]', 'ig'), 'e')
        .replace(new RegExp('[ìíîïîî]', 'ig'), 'i')
        .replace(new RegExp('[òóôö]', 'ig'), 'o')
        .replace(new RegExp('[ùúûü]', 'ig'), 'u')
        .replace(new RegExp('[ñ]', 'ig'), 'n')
        .replace(new RegExp('[șȘşŞ]', 'ig'), 's')
        .replace(new RegExp('[țȚţŢ]', 'ig'), 't')
        .replace(new RegExp('#', 'ig'), spacer + 'sharp')
        .replace(new RegExp('[^a-z0-9' + spacer + allowedChars + ']', 'ig'), spacer);

    let result = str.replace(new RegExp(spacer + '+', 'ig'), spacer); // cleanup multiple spacers

    // cleanup spacer from beginning
    if (result.startsWith(spacer)) {
        result = customTrim(result, spacer);
    }

    // cleanup spacer from end
    if (result.endsWith(spacer)) {
        result = customTrim(result, spacer);
    }

    return result.trim();
}

export function customTrim(s, mask) {
    while (~mask.indexOf(s[0])) {
        s = s.slice(1);
    }
    while (~mask.indexOf(s[s.length - 1])) {
        s = s.slice(0, -1);
    }
    return s;
}

// the alert is based on query Url, and after the confirm the query is removed
export function displayAlert() {
    // display message alerts
    const type = new URLSearchParams(window.location.search).get('type');
    const text = new URLSearchParams(window.location.search).get('text');
    if (type && text)
        if (new URLSearchParams(window.location.search).get('toast') !== undefined) {
            window.toast({ type, text, seconds: 10 });
            window.history.pushState(null, window.document.title, window.location.pathname);
        } else {
            window.swal({
                type,
                text,
                showCancelButton: false,
                onConfirm: (close) => {
                    window.history.pushState(null, window.document.title, window.location.pathname);
                    close();
                },
            });
        }
}

export const getInitials = (name) => {
    let initials = [];
    name.split(' ').forEach((e) => {
        if (e.length > 0) {
            initials.push(e.substr(0, 1).toUpperCase());
        }
    });
    return initials.join('');
};

export const dateDiff = (startDate, endDate = new Date(), returnType = 'string') => {
    if (startDate.constructor.name === 'String') {
        startDate = new Date(startDate);
    }
    if (endDate.constructor.name === 'String') {
        endDate = new Date(endDate);
    }
    const mStartDate = moment(startDate);
    const mEndDate = moment(endDate);

    const yearsDiff = mEndDate.diff(mStartDate, 'years');
    const monthsDiff = mEndDate.diff(mStartDate, 'months') - yearsDiff * 12;

    switch (returnType) {
        case 'string':
            let finalArr = [];
            const yearsResult = yearsDiff > 0 ? yearsDiff + ' ' + (yearsDiff === 1 ? 'Year' : 'Years') : '';
            if (yearsResult.length > 0) {
                finalArr.push(yearsResult);
            }
            const monthsResult = monthsDiff > 0 ? monthsDiff + ' ' + (monthsDiff === 1 ? 'Month' : 'Months') : '';
            if (monthsResult.length > 0) {
                finalArr.push(monthsResult);
            }
            return finalArr.join(', ');

        default:
            return [yearsDiff, monthsDiff];
    }
};

export const getFileIcon = (type) => {
    switch (type) {
        case 'document':
            return IconDocuments;

        case 'portfolio':
            return IconPortofolios;

        case 'reports':
            return IconReport;

        case 'project':
            return IconProjects;

        case 'video':
            return IconVideoFile;

        case 'image':
            return IconMedia;

        default:
            return null;
    }
};

window.dateDiff = dateDiff; // @debug

// used to compute HSL color for a given string
function stringToHslColor(str, s, l) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var h = hash % 360;
    return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
}
export const getColorName = (name) => {
    let s = 80; //100;
    var l = 30; //15;
    //var textColor = l > 70 ? '#555' : '#fff';
    var hexColor = stringToHslColor(name, s, l);
    return hexColor;
};

export function getBrowserName() {
    var name = 'unknown';
    if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.userAgent.indexOf('Trident/') !== -1) {
        name = 'msie';
    } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
        name = 'firefox';
    } else if (navigator.userAgent.indexOf('Opera') !== -1) {
        name = 'opera';
    } else if (navigator.userAgent.indexOf('Chrome') !== -1) {
        name = 'chrome';
        // } else if (navigator.userAgent.indexOf('Safari') !== -1) {
        // 	name = 'Safari';
    } else if (
        navigator.vendor &&
        navigator.vendor.indexOf('Apple') > -1 &&
        navigator.userAgent &&
        navigator.userAgent.indexOf('CriOS') === -1 &&
        navigator.userAgent.indexOf('FxiOS') === -1
    ) {
        name = 'safari';
    }
    return name;
}

export function getBrowserPlatformName() {
    var name = 'unknown';
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('android') > -1 && ua.indexOf('mobile') !== false && ua.indexOf('Chrome') !== -1) {
        name = 'android';
    } else {
        name = 'unknown';
    }
    return name;
}

// ModalNotMediaSupport
export function ModalSafariNotSupported({ trigger }) {
    return (
        <Modal trigger={trigger} className="notSuported-modal">
            {(handleClose) => (
                <div className="modal-content text-center">
                    <div className="modal-header">
                        <i className="far fa-exclamation-triangle attention" />
                        <h2>Browser not supported</h2>
                        <i className="far fa-times close" onClick={handleClose} />
                    </div>
                    <div className="modal-body">
                        <p>This feature is not fully supported in Safari!</p>
                        <p>As an alternative, you can use Chrome or Firefox browser.</p>
                    </div>
                </div>
            )}
        </Modal>
    );
}
ModalSafariNotSupported.propTypes = {
    trigger: PropTypes.func,
};

export function BrowserFeatureNotSupported({ trigger, feature }) {
    return (
        <Modal trigger={trigger} className="notSuported-modal">
            {(handleClose) => (
                <div className="modal-content text-center">
                    <div className="modal-header">
                        <i className="far fa-exclamation-triangle attention" />
                        <h2>Feature not supported</h2>
                        <i className="far fa-times close" onClick={handleClose} />
                    </div>
                    <div className="modal-body">
                        <p>
                            {feature} is not fully supported in your browser!{/*<em>({getBrowserName()})</em>*/}
                        </p>
                        <p>As an alternative, you could try Chrome or Firefox browsers.</p>
                    </div>
                </div>
            )}
        </Modal>
    );
}
BrowserFeatureNotSupported.propTypes = {
    trigger: PropTypes.func,
    feature: PropTypes.string,
};
BrowserFeatureNotSupported.defaultProps = {
    feature: 'Some feature',
};

export function MobileBrowserNotSupported({ trigger }) {
    return (
        <Modal trigger={trigger} className="notSuported-modal">
            {(handleClose) => (
                <div className="modal-content text-center">
                    <div className="modal-header">
                        <i className="far fa-exclamation-triangle attention" />
                        <h2>Feature not supported</h2>
                        <i className="far fa-times close" onClick={handleClose} />
                    </div>
                    <div className="modal-body">
                        <p>This feature is not fully supported on mobile.</p>
                        <p>Please switch to a desktop in order to use this feature.</p>
                    </div>
                </div>
            )}
        </Modal>
    );
}
MobileBrowserNotSupported.propTypes = {
    trigger: PropTypes.func,
};

//promt download
export function saveAs(uri, filename) {
    var link = document.createElement('a');
    if (typeof link.download === 'string') {
        link.href = uri;
        link.download = filename;
        //Firefox requires the link to be in the body
        document.body.appendChild(link);
        //simulate click
        link.click();
        //remove the link when done
        document.body.removeChild(link);
    } else {
        window.open(uri);
    }
}

export function structToQueryParams(params, encode = true) {
    return Object.keys(params)
        .map((key) => {
            if (encode) {
                return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
            } else {
                return key + '=' + params[key];
            }
        })
        .join('&');
}

export function useResponsiveViewport() {
    const [widthViewport, setWidthViewport] = React.useState(window.innerWidth);
    React.useEffect(() => {
        const handleWindowResize = () => setWidthViewport(window.innerWidth);
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    // Return the width so we can use it in our components
    return { widthViewport };
}

export function getVideoImage(path, callback) {
    let video = document.createElement('video');
    video.src = path;
    video.currentTime = 1;
    video.setAttribute('crossOrigin', 'anonymous');
    let canvas = document.createElement('canvas');
    let context = canvas.getContext('2d');
    video.addEventListener(
        'loadeddata',
        () =>
            setTimeout(() => {
                //console.log('vd', video.duration);
                context.drawImage(video, 0, 0, canvas.width, canvas.height);
                let dataURI = canvas.toDataURL();
                callback(dataURI);
                console.log('dataURI==', dataURI);
            }, 1000),
        false
    );
}

export function getVimeoImage(videoId, callback) {
    fetch('https://vimeo.com/api/v2/video/' + videoId + '.json')
        .then((response) => {
            return response.json();
        })
        .then((r) => {
            callback(r[0].thumbnail_large, convertTime(r[0].duration));
        })
        .catch((e) => console.log(e));
}

export function SalaryProcess({ salary, salaryFrom, salaryTo, salaryCurrency, salaryInterval }, defaultCurrency = 'eur') {
    // if (window.devMode) window.console.log('util/SalaryProcess', {salary, salaryFrom, salaryTo, salaryCurrency, salaryInterval, defaultCurrency}); // @debug
    // let classesSalary;
    const jobSalary = !salary ? '' : salary + ''; // !salary checks for empty string, 0, null, undefined, false and NaN; see http://stackoverflow.com/questions/6003884/ddg#6003920
    switch (salaryInterval) {
        case 'isNegotiable':
            return 'Negotiable';

        case 'notDisclosed':
            return 'Not Disclosed';

        default:
            // annually, hourly
            const salaryKey = jobSalary.trim().toLowerCase();
            switch (salaryKey) {
                case 'hourly':
                    return 'Hourly';

                case 'negotiable':
                    return 'Negotiable';

                case 'not disclosed':
                    return 'Not Disclosed';

                default:
                    let salaryData = [];
                    if (!salary || salaryKey === 'custom range') {
                        salaryData = [salaryFrom, '-', salaryTo];
                    } else {
                        // range
                        salaryData = [salary];
                    }
                    if (salaryInterval === 'hourly') {
                        salaryData.push('p/h');
                    }
                    return salaryData.join(' ');
            }
    }
}

function convertTime(sec) {
    var hours = Math.floor(sec / 3600);
    hours >= 1 ? (sec = sec - hours * 3600) : (hours = '00');
    var min = Math.floor(sec / 60);
    min >= 1 ? (sec = sec - min * 60) : (min = '00');
    sec < 1 ? (sec = '00') : void 0;

    min.toString().length === 1 ? (min = '0' + min) : void 0;
    sec.toString().length === 1 ? (sec = '0' + sec) : void 0;

    return hours > 1 ? hours + ':' : '' + min + ':' + sec;
}

export function getCompanyUsers(state) {
    // return Array.isArray(state.company.parentUsers) ? [].concat(state.users, state.company.parentUsers) : state.users;
    return state.hierarchyUsers;
}

export function nl2br(str, is_xhtml = true) {
    if (typeof str === 'undefined' || str === null) {
        return '';
    }
    var breakTag = is_xhtml || typeof is_xhtml === 'undefined' ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}

export function usePrevious(value) {
    const ref = React.useRef();
    React.useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export function getTopOffset(el) {
    const labelOffset = 180;
    // if (window.devMode) window.console.log('Fields/getTopOffset/el', el);
    return el?.getBoundingClientRect().top + window.scrollY - labelOffset;
}

export function BackgroundOverlayWrap() {
    React.useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => (document.body.style.overflow = 'auto');
    }, []);
    return <BackgroundWrapSt />;
}

export function getStepName(list, id) {
    const stepFound = list.filter((step) => step.id === id);
    if (stepFound.length === 1) {
        return stepFound[0].name;
    } else {
        return 'step not found!';
    }
}

export function getIconStep(name) {
    switch (name) {
        case 'Shortlist':
            return <IconShortlist />;
        case 'Assessment':
            return <IconBriefCase />;
        case 'Video Interview':
            return <IconVideoInterview />;
        case 'Interview':
            return <IconFaceToFace />;
        case 'Call':
            return <IconCall />;
        case 'Vetting':
            return <IconVetting />;
        case 'Offer':
            return <IconOffer />;
        default:
            return null;
    }
}

export function getStepIcon(iconKey) {
    switch (iconKey) {
        case 'fa-list-ul':
            return <IconShortlist />;
        case 'fa-briefcase':
            return <IconBriefCase />;
        case 'fa-coffee':
            return <IconVideoInterview />;
        case 'fa-user':
            return <IconFaceToFace />;
        case 'fa-phone':
            return <IconCall />;
        case 'fa-envelope':
            return <IconVetting />;
        case 'fa-offer':
            return <IconOffer />;
        case 'fa-hire':
            return <IconHire />;
        case 'fa-users':
            return <IconUsers />;
        default:
            return null;
    }
}
