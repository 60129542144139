import React, { useState } from 'react';
import { apiFetch, preLoading } from '_dash/components/util';
import { Provider } from 'react-redux';
import store from '_dash/redux/store/index';
// import { Helmet } from 'react-helmet';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import 'scss/applicationSt.scss';
import SwaggerPage from 'SwaggerPage';

// import PropTypes from 'prop-types';
const Dash = React.lazy(() => import('./Dash'));
// import Dash from './Dash';
const Front = React.lazy(() => import('./FrontWrapper'));
const FrontCompanyIntegration = React.lazy(() => import('./FrontCompanyIntegration'));
const CvView = React.lazy(() => import('_dash/pages/jobSeeker/CvView'));
const HSE_Form = React.lazy(() => import('_dash/front/HSE_form_login/HSE_form'));
const HSE_form_questions = React.lazy(() => import('_dash/front/HSE_form_questions/HSE_form_questions'));
const HSE_form_iframe = React.lazy(() => import('_dash/front/HSE_form_login/HSE_form_iframe'));
// import Front from './Front';
// const Dash = <h1>hello</h1>;

export default function App() {
    const [state, setState] = useState(window.initData);
    React.useEffect(() => {
        if (process.env.NODE_ENV === 'development' && state === undefined) {
            const dataFetch = {
                action: 'api.baseapi.getDevReactInitData',
                companySubdomain: '',
                path: window.location.pathname,
            };
            // ignore subdomain on ngrok
            if (window.location.hostname.search('.ngrok.io') === -1) {
                //get companyUrl from subdomain if exist
                const hostNameDot: any[] = window.location.hostname.split('.'); //ex www.rezoomo.com, myCompanyUrl.rezoomo.com
                //check if is company subdomain url,and exclude www(for live) and bs-local(for browserStack local)
                const excludePrefixes = ['www', 'bs-local'];
                if (hostNameDot.length > 1 && excludePrefixes.indexOf(hostNameDot[0]) === -1) {
                    //don't allow number as subdomain in case we use the ip as domain
                    if (isNaN(hostNameDot[0])) dataFetch.companySubdomain = hostNameDot[0];
                }
            }

            // get initData
            apiFetch(dataFetch).then((data) => {
                setState(data);
                window.initData = data;
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderExternalRoute = (routeProps: any) => {
        // window.console.log('app.jsx/renderExternalRoute/locationSearch', routeProps.location.search); // @debug
        const searchParams = new URLSearchParams(routeProps.location.search);
        const sourceParam = searchParams.get('source'); // null, rezoomo, internal vs default
        
        // TODO @Mihai: is this still accurate?
        // maybe checking against specific sources ie. ['iframe', 'light', 'intranet'] would be more accurate;
        // as it is now, it includes ie. source=jobs.ie which is wrong
        const isExternal = [null, 'rezoomo', 'internal', ''].indexOf(sourceParam) === -1;
        // window.console.log('renderCompanyRoute', routeProps, sourceParam, isExternal); // @debug
        
        const frontUrlData = { ...state.url, subpage: routeProps.match.params.subpage, isExternal };
        if (isExternal && typeof sourceParam === 'string') {
            frontUrlData['source'] = sourceParam;
            if (['iframe', 'light', 'intranet'].indexOf(sourceParam) > -1) {
                // if (sourceParam === 'iframe') {
                searchParams.forEach((value, key) => {
                    frontUrlData[key] = value;
                });
            }
        }
        if (state.store.companySubdomain.length > 0) {
            return <FrontCompanyIntegration urlData={state.url} dataPage={state.page} />;
        } else {
            return <Front page={state.url.page} dataPage={state.page} urlData={frontUrlData} />;
        }
    };

    const renderDefaultRoute = (routeProps: any) => {
        // if (window.devMode) window.console.log('renderDefaultRoute - routeProps', routeProps); // @debug
        if (state.status === 'view_cv') {
            if (state.store.cv) {
                return <CvView action="api.baseapi.getCvData" recordView={true} />;
            } else {
                return <Front page="cv_is_not_published" dataPage={state.page} urlData={state.url} />;
            }
        } else if (state.store.companySubdomain.length > 0) {
            return <FrontCompanyIntegration urlData={state.url} dataPage={state.page} />;
        } else {
            return <Front page={state.url.page} dataPage={state.page} urlData={state.url} urlParams={routeProps.match.params} />;
        }
    };

    if (state === undefined) {
        return preLoading();
    } else {
        if (window.devMode) window.console.log(1, 'init store');
        switch (state.status) {
            case 'company_not_found':
                return (
                    <div>
                        {/* <Helmet>
                            <meta name="robots" content="noindex" />
                        </Helmet> */}
                        <div>
                            <h3>Company not Found.</h3>
                            <p>1. Most likely, you have probably just slightly misspelt the address {window.location.hostname} so before we go any further please check that.</p>
                            <p>2. OK, there is also the slim possibility that the account may have been deleted.</p>
                        </div>
                    </div>
                );
            case 'company_not_published':
                return (
                    <div>
                        {/* <Helmet>
                            <meta name="robots" content="noindex" />
                        </Helmet> */}
                        <h3>Company not Published.</h3>
                    </div>
                );
            case 'company_integration_not_published':
                return (
                    <div>
                        {/* <Helmet>
                            <meta name="robots" content="noindex" />
                        </Helmet> */}
                        <h3>Company Integration not Published.</h3>
                    </div>
                );
            default:
                // set body class
                if (state.url.companySubdomain.length > 0) {
                    document.body.className = 'company-body';
                } else {
                    switch (state.url.page) {
                        case 'home':
                            document.body.className = 'home';
                            break;
                        case 'login':
                            document.body.className = 'login-page';
                            break;
                        case 'dash':
                            document.body.className = 'fixed-header';
                            break;
                        case 'job':
                        case 'company':
                            document.body.className = 'company-body';
                            break;
                        default:
                            document.body.className = 'inner-pages';
                            break;
                    }
                }
                if (state.store.navData !== undefined && state.store.userData !== undefined) {
                    state.store.userData.dashPages = customDashPages;
                    state.store.navData.forEach((e: { path: string; name: string }) => {
                        state.store.userData.dashPages[e.path] = e.name;
                    });
                }
                return (
                    <React.Suspense fallback={preLoading()}>
                        {/* no need in production because is already updated by server */}
                        {/* {process.env.NODE_ENV === 'development' && (
                            <Helmet>
                                <title>{state.page.title}</title>
                                <meta name="description" content={state.page.description} />
                                <meta name="keywords" content={state.page.keywords} />
                                <meta name="robots" content={state.page.robots} />
                                {/* twitter card *\/}
                                <meta name="twitter:card" content="summary" />
                                <meta name="twitter:title" content={state.page.title} />
                                <meta name="twitter:description" content={state.page.description} />
                                <meta property="twitter:url" content={state.page.canonical} />
                                <meta property="twitter:image" content={state.page.logoUrl} />
                                {/* facebook *\/}
                                <meta property="og:title" content={state.page.title} />
                                <meta property="og:image" content={state.page.logoUrl} />
                                <meta property="og:url" content={state.page.canonical} />
                                <meta property="og:description" content={state.page.description} />
                                {/* canonical *\/}
                                <link rel="canonical" href={state.page.canonical} />
                                {/* favicon *\/}
                                {/* <link rel="icon" type="image/png" href={process.env.REACT_APP_SITE_URL + state.page.favicon} sizes="32x32" /> *\/}
                                <link rel="icon" type="image/png" href={state.page.favicon} sizes="32x32" />
                            </Helmet>
                        )} */}
                        <Provider store={store(state.store)}>
                            <BrowserRouter>
                                <Switch>
                                    <Route path="/api-docs/" render={(props) => <SwaggerPage />} />
                                    <Route path="/dash/" render={(props) => <Dash />} />
                                    <Route path="/hse-form/" render={(props) => <HSE_Form />} />
                                    <Route path="/hse-form-questions/" render={(props) => <HSE_form_questions />} />
                                    <Route path="/hse-form-iframe/" render={(props) => <HSE_form_iframe />} />
                                    <Route path="/company/:companyurl/:subpage" render={renderExternalRoute} />
                                    <Route path="/job/:jobId" render={renderExternalRoute} />
                                    <Route path="/calendar-confirm-event/:evPid" render={renderDefaultRoute} />
                                    <Route path="/e-sign-document/:signerToken" render={renderDefaultRoute} />
                                    <Route render={renderDefaultRoute} />
                                </Switch>
                            </BrowserRouter>
                        </Provider>
                    </React.Suspense>
                );
        }
    }
}

// App.propTypes = {
// 	// page: PropTypes.string.isRequired
// };

const customDashPages = {
    '/dash/job/': 'Job',
    '/dash/jobs/': 'Jobs',
    '/dash/jobs-requisition/': 'Job requisition',
    '/dash/academy/': 'Academy',
    '/dash/email/inbox/': 'Messages',
    '/dash/email/starred/': 'Messages',
    '/dash/email/sent/': 'Messages',
    '/dash/email/archive/': 'Messages',
    '/dash/email/trash/': 'Messages',
    '/dash/company/': 'Setup guide',
    '/dash/job-candidate-flow/': 'Candidate flow',
    '/default-messages': 'Default messages',
    '/dash/users/': 'Users',
};
